import { Card } from '@repo/ui/components/Card.js'
import { Logo } from '@repo/ui/components/Logo.js'
import { Separator } from '@repo/ui/components/Separator.js'
import { Spacer } from '@repo/ui/components/Spacer.js'
import { Outlet } from 'react-router'

export default function AuthLayout() {
  return (
    <div className="flex h-full min-h-svh flex-col items-center justify-center bg-gradient-to-br from-purple-800 to-pink-400 text-md">
      <div className="-translate-x-1/2 absolute top-4 left-1/2 flex items-center gap-2">
        <Logo className="w-32 text-white" />
        <Separator
          orientation="vertical"
          className="h-6 w-0.5 rotate-12 bg-gray-300"
        />
        <span className="mb-1 font-medium text-pink-400 text-xl tracking-wide">
          pulse
        </span>
      </div>
      <Spacer size="md" />
      <Card className="min-w-96 max-w-lg bg-card/80 shadow-lg backdrop-blur-2xl">
        <Outlet />
      </Card>
      <param name="" value="" />
    </div>
  )
}
